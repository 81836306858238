<template>
  <div  style="vertical-align: middle; background-color: #1e991d">
  <navbar
      position= "fixed"
      type= "primary"
      transparent
      menu-classes= "ml-auto"
      class= "z-index-high"
  >
    <!--<template>
      <div v-popover:popover1 class="navbar-brand">
         Menu
       </div>
       <el-popover
           ref="popover1"
           popper-class="popover"
           placement="bottom"
           width="250"
           trigger="hover"
       >
         <div class="popover-body">
           <router-link to="/AboutUs">About Us</router-link>
         </div>
         <div class="popover-body">
           <router-link to="/ContactUs">Contact Us</router-link>
         </div>
       </el-popover>
     </template>-->
   <template slot="navbar-menu" slot-scope="{close}">
      <li class="nav-item" @click="close">
        <a
            class="router-link"
            rel="tooltip"
            title=" Home"
            data-placement="bottom"
            href="index.html"
        >
          <i class="fab el-icon-s-home"></i>
          <p class="d-lg-none d-xl-none">&nbsp;Home</p>
        </a>
      </li>
      <li class="nav-item" @click="close">
        <a
            class="router-link"
            rel="tooltip"
            title="Follow us on Facebook"
            data-placement="bottom"
            href="https://www.facebook.com/Fingerlakesceltic"
            target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">&nbsp;Facebook</p>&nbsp;&nbsp;
        </a>
      </li>
     <li class="nav-item" @click="close">
     <router-link class="router-link" to="/AboutUs">About Us </router-link>&nbsp;&nbsp;
     </li>
     <li class="nav-item" @click="close">
       <router-link class="router-link" to="/ClanInfo">Clan/Society Info </router-link>&nbsp;&nbsp;
     </li>
     <li class="nav-item" @click="close">
       <router-link class="router-link" to="/ContactUs">Contact Us </router-link>&nbsp;&nbsp;
     </li>
     <li class="nav-item" @click="close">
       <router-link class="router-link" to="/PhotoGallery">Photo Gallery </router-link>&nbsp;&nbsp;
     </li>
      <!--
      <drop-down tag="li" title="Menu">
        <i><b>Menu</b></i><br>
        <nav-link class="dropdown-item">
          <i><router-link to="/AboutUs">About Us</router-link></i><br>
        </nav-link>
        <nav-link class="dropdown-item">
          <i><router-link to="/ContactUs">Contact Us</router-link></i>
        </nav-link>
        <nav-link class="dropdown-item">
          <i><router-link to="/PhotoGallery">Photo Gallery</router-link></i><br>
        </nav-link>
      </drop-down>-->
  </template>
 </navbar>
  </div>
</template>

<script>
//import { DropDown, Navbar, NavLink } from '@/components';
import { Navbar} from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'main-navbar',
  props: {
    transparent:  {
      type: Boolean,
      default: false
    },
    colorOnScroll: Number
  },
  components: {
   //DropDown,
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
