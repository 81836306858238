<template>
 <div>
<div class="page-header clear-filter">
  <parallax
      class="page-header-image"
      style="background-color: darkgreen; background-image:url('img/pippers_on_the_field.jpg'); background-size: 75%; background-blend-mode: lighten; background-repeat: no-repeat;"
  >
  </parallax>
  <div class="container">
    <div class="h1-seo"></div>
    <div class="content-center brand">

      <div style="backdrop-filter: blur(5px); text-align: left; height: max-content;">
        <b-card img-src="img/pippers_collage.jpg" alt="Pipper Collage" img-bottom style="padding-bottom: 200px;">
          <b-card-header> <img  src="img/logo.jpg" style="width:200px;" alt="" />
          <b-card-text style="font-size: large; font-family: 'Times New Roman';">
            <b>The Finger Lakes Celtic Society Inc. is a 501(c)(3) not-for-profit organization registered and operating in New York State for the purpose of preserving, sharing, and promoting knowledge of the history and heritage of that part of the world known as the 7 Celtic Nations through the presentation of information and demonstrations of the Arts, crafts, foods, and traditions of these Nations.</b>
          </b-card-text></b-card-header>
        </b-card>
      </div>
    </div>
  </div>
</div>
</div>

</template>
<script>
import { Parallax } from '@/components';
export default {
  name: 'AboutUs',
  bodyClass: 'index-page',
  components: {
    Parallax //,
    //BasicElements,
    //Navigation,
    //TabsSection,
    //ProgressPagination,
    //Notifications,
    //Typography,
    //JavascriptComponents,
    //CarouselSection,
    //NucleoIconsSection,
    //SignupForm,
    //ExamplesSection,
    //DownloadSection
  }
};
</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}
</style>
