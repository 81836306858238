<template>
 <div>
<div class="page-header clear-filter">
  <parallax
      class="page-header-image"
      style="background-color: darkgreen; background-image:url('img/pippers_on_the_field.jpg'); background-size: 75%; background-blend-mode: lighten; background-repeat: no-repeat;"
  >
  </parallax>
  <div class="container">
    <div class="h1-seo"></div>
    <div class="content-center brand">

      <div style="backdrop-filter: blur(5px); text-align: left; height: max-content;"><b-card>
          <b-card-header> <img  src="img/logo.jpg" style="width:200px;" alt="" />
          <b-card-text style="font-size: large; font-family: 'Times New Roman';">
            <b>Please contact<br>
              Cat Chapman<br>
              celticinspired@gmail.com<br>
              716.574.0575<br>
              For information regarding clans and registration.</b>
           <!-- <b>Hello!</b><br>
              <p>My name is Kathy Williams. This is my first year as Clan Coordinator and I want to challenge all of you to make it one of the best. Having the most Clans and Societies present at our games versus any other local event is my goal, as has been a similar goal in the past. Help me carry the torch well!
                This year we are at a new location. We will be in Canandaigua at Lincoln Hill Farms.<br></p>
              <p style="text-align: center"><b>Lincoln Hill Farms<br>
              3792 NY 247<br>
                Canandaigua, NY 14424</b><br></p>
            <p>You may fill out/mail or email me the enclosed registration form.<br></p>
            <p style="text-align: center"><b><a href="pdfs/23FLCFEntry.pdf" download="23FLCFEntry.pdf">FLCF Entry Form (pdf) <img src="img/23FLCFEntry.png"></a></b></p><br>
             <p>Any questions? Just ask and I’ll try to answer them. Most of them are explained in the attachments.<br>
              Once again THIS year - an all-day Clan/Society dish-to-pass picnic/potluck at the “Clan Central” tent. Please plan on contributing a dish, dessert, or snack to this endeavor. Bring your stampers for the kids and kids at heart.
              Also, be sure to check out our newly and continuously updated website https://flceltic.org/ to see what we’re all about. The information must be sent to me no later than April 21st, 2023 as the program goes to press shortly thereafter. You may send the information directly to me at: <b><a href = "mailto: clans.fingerlakescelticfest@gmail.com">clans.fingerlakescelticfest@gmail.com</a></b>.
                Thank you and let’s see if we can raise that number a whole lot. I can’t wait to hear from you.<br></p>
              <br>
              <p style="text-align: center"><b>Kathy Williams<br>
              Clan Coordinator<br>
              7417 Maplehurst Road Cicero, NY 13039<br>
                (680)800-7755</b><br>
              <b><a href = "mailto: clans.fingerlakescelticfest@gmail.com">clans.fingerlakescelticfest@gmail.com</a></b></p>
            <br><br><br> -->
            <!--<br>
            <P>Hello!
            <img src="img/krystas_headshot.JPG" style="height: 200px; float:left; margin-right: 10px;"></P>
            <p>My name is Krista, and I am the 2024 Society/Clans Coordinator for the 2024 Fingerlakes Celtic Games & Festival. This is my first year as Clan Coordinator for this event, but I have coordinated many festivals and games. This year, I want you to have the best experience possible!</p>
            <br><br>
            <h3>Registration Information</h3>
            <p>Registration is once again free. However, all registrations must be completed online; mail-in registrations are not available. Once you complete the form, you will get an automated email confirmation of your registration. Registrations should be received no later than April 1st, 8 am, to be included in the program. Registrations received later than that date will not be in the program, however, registrations can be accepted up until May 4th. We have space for 30 clans/societies, so be sure to register early to ensure you get a spot. There is no electricity available.</p>
            <br>-->

            <!-- form.123formbuilder.com script begins here -->
            <!--<iframe allowTransparency="true" style="min-height: 400px; height: inherit; overflow: auto;" width="100%" id="contactform123" name="contactform123" marginwidth="10" marginheight="50" frameborder="1" src="https://form.123formbuilder.com/my-contact-form-6580123.html?customVars123=yes&hasEmbedFormStyle=1">
              <p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p>
            </iframe>-->
            <!-- form.123formbuilder.com script ends here -->

           <!-- <div style="text-align: center"><button style=" margin: auto; background-color: #4CD964" @click="openPopup">Click To Register</button></div> -->
<!--
            <br><br><br>
            <div style="align-content: center">
              <ul><h3>Confirmed For 2024</h3>
              <li>Ancient order of the Hibernians</li>
              <li>Clan Baird</li>
              <li>Clan Davidson</li>
              <li>Clan Donnachaidh</li>
              <li>Clan Ferguson</li>
              <li>Clan Fraser</li>
              <li>Clan Gregor</li>
              <li>Clan Hall Society</li>
              <li>Clan Hannay Society</li>
              <li>Clan Hay Society</li>
              <li>Clan Keith</li>
              <li>Clan Kerr</li>
              <li>Clan Mac Kinnon</li>
              <li>Clan MaCGillvray</li>
              <li>Clan MacKay Society</li>
              <li>Clan Macnachtan</li>
              <li>Clan MacNeil</li>
              <li>Clan McPherson</li>
              <li>Clan Stewart</li>
              <li>Clan Thompson</li>
              <li>Clan Young</li>
              <li>Daughters of Scotia</li>
              <li>Family of Bruce</li>
              <li>House of Gordon</li>
              <li>Scottish American Military Society</li>
              <li>Scottish Heritage Society of Rochester, Inc.</li>
            </ul></div>

            <br><br><br>
            <h3>Load-in Instructions</h3>
            <p>You will get load-in instructions seven days prior to the event. This will include a copy of your registration, a map of the venue, a map for load-in, and information with your booth number assignment. You will also get a parking pass or disabled parking pass if you have a state-issued disabled parking permit and have received a confirmation designated parking is available for you. These parking spaces are limited and first-come-first-served. However, all clans/societies have parking very close to the clan area and front gate. There is a designated port-a-potty for clans/societies directly near the clans/societies area.</p>

            <h2>Load-in Options</h2>
            <p>You have the option to load-in on Friday between 12-6 pm or Saturday between 6 am and 8:30 am. All vehicles must be off the field and parked in the lot by 8:30 am Saturday and ready to begin at the event start, 9 am.</p>

            <h3>Event Details</h3>
            <p>Having many Clans and Societies present at our games is our goal, as has been a similar goal in the past. This is the 2nd year in Canandaigua at Lincoln Hill Farms but our 13th year for this event! Information about the venue can be found here: <a href="https://lincolnhillfarms.com/">Lincoln Hill Farms</a> and here: <a href="https://lincolnhillfarms.com/event/flcelticfest/">Event Details</a>.</p>

            <h3>Clan/Society Dish-to-pass Picnic/Potluck</h3>
            <p>This year, we are offering a booth for an all-day Clan/Society dish-to-pass picnic/potluck at the “Clan Central” tent. Please plan on contributing a dish, dessert, snacks, or non-alcoholic beverages if you wish to participate in the food and drinks.</p>

            <p>Bring your clan stamp for the kids and kids at heart!</p>

            <p>Also, be sure to check out our newly and continuously updated website <a href="https://flceltic.org/">here</a> to see what is new and details about the event!</p>

            <h3>Contact Information</h3>
            <p>If you have any additional questions, please email me at <a href="mailto:fingerlakesclans@gmail.com">fingerlakesclans@gmail.com</a>. I will do my best to reply via email promptly. I prefer to have communications via email to ensure a record of communications. For this reason, I do not provide my phone number until you receive your load-in packet seven days prior to the event.</p>

            <p>We hope to see you at the games!</p>
            <br><br><br>  -->

          </b-card-text></b-card-header>
       </b-card>
      </div>
    </div>
  </div>
</div>
</div>

</template>
<script>
import { Parallax } from '@/components';
export default {
  name: 'AboutUs',
  bodyClass: 'index-page',
  components: {
    Parallax //,
    //BasicElements,
    //Navigation,
    //TabsSection,
    //ProgressPagination,
    //Notifications,
    //Typography,
    //JavascriptComponents,
    //CarouselSection,
    //NucleoIconsSection,
    //SignupForm,
    //ExamplesSection,
    //DownloadSection
  },
  methods: {
    openPopup() {
      // URL of the form from a different domain
      const iframeUrl = 'https://form.123formbuilder.com/my-contact-form-6580123.html?customVars123=yes&hasEmbedFormStyle=1';

      // Width and height of the popup window
      const width = 600;
      const height = 400;

      // Calculate the center position of the screen
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;

      // Open the popup window
      const popupWindow = window.open(iframeUrl, 'Popup Window', `width=${width}, height=${height}, left=${left}, top=${top}`);

      // Optionally, you can add an event listener to handle actions when the popup window is closed
     // if (popupWindow) {
       // popupWindow.addEventListener('beforeunload', () => {
          // Handle actions when the popup window is closed
        //  console.log('Popup window closed');
      //  });
     // }
    }
  }
};
</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}
</style>
