<template>
  <b-container fluid>
    <div class="gallery-grid">
      <div
          v-for="image in images"
          :key="image"
          :class="{'portrait': isPortrait(image), 'landscape': !isPortrait(image)}"
          class="gallery-item"
      >
        <b-img
            :src="`img/gallery/${image}`"
            thumbnail
            fluid
            alt="thumbnail"
            @click="showImage(image)"
        />
      </div>
    </div>

    <b-modal
        v-model="isModalVisible"
        size="lg"
        hide-footer
        centered
        :title="modalTitle"
    >
      <div class="d-flex justify-content-center align-items-center modal-content">
        <b-button variant="dark" class="arrow left-arrow" @click="prevImage">‹</b-button>
        <img :src="`img/gallery/${selectedImage}`" class="full-image" />
        <b-button variant="dark" class="arrow right-arrow" @click="nextImage">›</b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      images: [],
      isModalVisible: false,
      selectedImage: '',
      modalTitle: 'Finger Lakes Celtic Games & Festival'
    };
  },
  mounted() {
    this.loadImages();
  },
  methods: {
    async loadImages() {
      try {
        const response = await fetch('img/galleryList.json');
        const images = await response.json();
        this.images = images;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to load images:', error);
      }
    },
    showImage(image) {
      this.selectedImage = image;
      this.isModalVisible = true;
    },
    prevImage() {
      const currentIndex = this.images.indexOf(this.selectedImage);
      const prevIndex = (currentIndex - 1 + this.images.length) % this.images.length;
      this.selectedImage = this.images[prevIndex];
    },
    nextImage() {
      const currentIndex = this.images.indexOf(this.selectedImage);
      const nextIndex = (currentIndex + 1) % this.images.length;
      this.selectedImage = this.images[nextIndex];
    },
    isPortrait(image) {
      // Placeholder function to determine if the image is portrait or landscape
      return image.includes('portrait');
    }
  }
};
</script>

<style scoped>
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding-bottom: 60px; /* Add padding to the bottom */
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: calc(100vh - 250px); /* Ensure the grid height is 100px less than the full screen height */
}

.gallery-item {
  position: relative;
}

.portrait {
  grid-row: span 2;
}

.thumbnail {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  position: relative;
  padding: 20px;
}

.full-image {
  max-width: calc(100% - 80px); /* Adjust for arrow buttons */
  max-height: 100vh;
  width: auto;
  height: auto;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: black;
  color: white;
  border: none;
  font-size: 2.5rem;
  z-index: 10;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
</style>
