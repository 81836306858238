<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="http://flceltic.org/">
              Finger Lakes Celtic Society Inc
            </a>
          </li>
          <li>
            <router-link to="/AboutUs">About Us </router-link>
          </li>
          <li>
            <router-link to="/ClanInfo">
              Clan/Society Info
            </router-link>
          </li>
          <li>
            <router-link to="/ContactUs">Contact Us </router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Finger Lakes Celtic Society Inc - All Rights Reserved.<br>
        Designed and Code Implemented By&nbsp;
        <a href="https:///MantisMan.com" target="_blank" rel="noopener">MantisMan LLC</a>.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.footer {
  position: relative;
}
</style>
