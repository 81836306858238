import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import StarterNavbar from './layout/MainNavbar.vue';
import StarterFooter from './layout/MainFooter.vue';
import AboutUs from "@/pages/AboutUs";
import ClanInfo from "@/pages/ClanInfo"
import ContactUs from "@/pages/ContactUs";
import PhotoGallery from "@/pages/PhotoGallery";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      components: {
        default: Index,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/aboutUs',
      name: 'AboutUs',
      components: { default: AboutUs, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/clanInfo',
      name: 'ClanInfo',
      components: { default: ClanInfo, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/contactUs',
      name: 'ContactUs',
      components: { default: ContactUs, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/photoGallery',
      name: 'PhotoGallery',
      components: { default: PhotoGallery, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
